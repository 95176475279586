<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('hall.default[0]')" @click-right="onClickRight"
    ><van-icon name="./static/icon/task00.png" slot="right" />
    </van-nav-bar>
    <div class="ScrollBox">
    <div class="tool">
        <table style="text-align: center;width: 95%;line-height: 25px">
          <tr><td> {{ $t("hall.default[1]") }}:(USDT)</td></tr>
          <tr><td> <span style="text-decoration:underline;font-size:18px"> {{ parseFloat(UserInfo.balance).toFixed(3)}}</span></td></tr>
          <tr><td> <span style="text-decoration:underline;">{{ $t("hall.default[8]") }}: {{ UserInfo.remains_numbers }} USDT </span></td></tr>
        </table>
    </div>
    <div style="margin: 5px;margin-top: 10px;margin-bottom: 10px;">
      <van-grid :border="false" :column-num="2" icon-size="50" gutter="5" direction="horizontal">
        <van-grid-item icon="./static/icon/buy.png" :text="$t('hall.default[2]')" @click="gotoBuy">
        </van-grid-item>
        <van-grid-item icon="./static/icon/sell.png" :text="$t('hall.default[3]')" to="/sell">
        </van-grid-item>
      </van-grid>
    </div>
    <van-swipe :autoplay="2000" :vertical="isVertical" v-if="robotList.length>0"
               indicator-color="#191C23"
               class="hallswipe">
      <van-swipe-item v-for="(item, index) in robotList" :key="index">
        <img class="robotheader" :src="`./static/head/${item.header}`" />
        <div style="margin-top: -42px;margin-left: 40px;color: #aaa">
          {{item.username}}
          {{$t('common5[0]')}} {{item.amount}}USDT {{item.time}}{{$t('common5[1]')}}{{$t('common5[2]')}}
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="title01">
      <van-grid :border="false" :column-num="2" icon-size="40" gutter="5">
        <van-grid-item>
          <van-button :color="active==0?'#0076FA':'#252D30'" class="tt3" @click="active=0">
            <span :class="active==0?'':'tt3'">{{ $t("hall.default[6]") }}</span></van-button>
        </van-grid-item>
        <van-grid-item>
          <van-button :color="active==1?'#0076FA':'#252D30'"  @click="active=1">
            <span :class="active==1?'':'tt3'">{{ $t("hall.default[7]") }}</span></van-button>
        </van-grid-item>
      </van-grid>
    </div>
      <div v-if="active==0">
        <div v-for="item in selllist" class="records">
          <table class="sellitem">
            <tr>
              <td style="width: 50%"> {{item.username}}</td>
              <td> {{ $t("hall.list[0]") }} {{parseFloat(item.total_number).toFixed(3)}} USDT</td>
            </tr>
            <tr>
              <td> {{ $t("hall.list[1]") }} {{parseFloat(item.price).toFixed(3)}}</td>
              <td> {{ $t("hall.list[2]") }} {{parseFloat(item.remains_number).toFixed(3)}} USDT</td>
            </tr>
            <tr>
              <td> </td>
              <td style="text-align: right">
<!--                <van-button size="normal" color="#F9941E" v-if="UserInfo.user_type==3"-->
<!--                            style="width: 80px;height: 28px" @click="gotoTask(1,item)">-->
<!--                  <span style="font-size: 14px" >{{ $t("hall.default[9]") }}</span></van-button>-->
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="active==1" class="ScrollBox">
        <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
          <van-list
                  v-model="isLoad"
                  :finished="isFinished"
                  :finished-text="listData.length ? $t('vanPull[0]') : $t('vanPull[1]')"
                  @load="onLoad"
                  :class="{ Empty: !listData.length }"
          >
            <div v-for="item in listData" class="records" style="color: #9b9b9b">
              <table class="sellitem">
                <tr>
                  <td style="width: 50%"> {{item.username}}</td>
                  <td> {{ $t("hall.list[0]") }} {{parseFloat(item.total_number).toFixed(3)}} USDT</td>
                </tr>
                <tr>
                  <td> {{ $t("hall.list[1]") }} {{parseFloat(item.price).toFixed(3)}}</td>
                  <td> {{ $t("hall.list[2]") }} {{parseFloat(item.remains_number).toFixed(3)}} USDT</td>
                </tr>
                <tr>
                  <td> {{ $t("hall.list[6]") }} {{parseFloat(item.min_number).toFixed(3)}}-{{parseFloat(item.max_number).toFixed(3)}} USDT</td>
                  <td style="text-align: right">  <van-button size="normal" color="#0FCB81" style="width: 80px;height: 28px"
                                                              @click="gotoTask(2,item)">
                    <span style="font-size: 14px" >{{ $t("hall.default[10]") }}</span></van-button></td>
                </tr>
              </table>
            </div>
          </van-list>
        </van-pull-refresh>

        <div style="height: 80px;"/>
      </div>
    </div>
    <Footer />
    <van-dialog v-model="show_buy"  :title="$t('hall.list[7]')" @confirm="addNewOrder"
                :cancel-button-text="$t('common[3]')" :confirm-button-text="$t('common[2]')"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
                v-model="postData.amount"
                :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[8]')}}::{{parseFloat(postData.amount*currItem.price).toFixed(3)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
    <van-dialog v-model="show_sell"  :title="$t('hall.list[3]')" @confirm="addNewOrder"
                :confirmButtonText="$t('common[2]')" :cancelButtonText="$t('common[3]')"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
                v-model="postData.amount"
                :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[5]')}}:{{parseFloat(postData.amount*currItem.price).toFixed(3)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      infoData: '',
      isVertical:true,
      show_sell:false,
      show_buy:false,
      postData:{task_id:0,amount:''},
      usdtamount:0,
      usdtinfo:null,
      currItem:{"price":0},
      robotList:[],
      selllist:[],
      listData: [],
      timer:null,
      isLoad: false,
      isFinished: false,
      isRefresh: false,
      pageNo: 0,
      hallInfo:{islock:1,shimingauth:1},
      active:0,
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo();
    let info = this.UserInfo;
    // debugger
    // if (!info['shimingauth']||info['shimingauth']!=1){
    //   this.$router.push("/shimingauth");
    //   return;
    // }

    var that = this;

    this.timer = setInterval(() => {
      // console.log("get prices");
     // that.findTradeStatus();
    }, 3000);

    let param0 = {};
    this.$Model.GetRobotTasks(param0,(data) => {
      // debugger
      if (data['code']==1){
        this.robotList = data['info']
      }
    });

    this.usdtinfo = this.InitData.usdtinfo;

    let param2 = {"task_type":2,status:1};
    this.$Model.GetHallTaskList(param2,(data) => {
      if (data['code']==1){
        this.selllist = data['info']
      }
    });
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  beforeDestroy() {
    console.log("clear  hall timer");
    clearInterval(this.timer);
  },
  methods: {
    onLoad() {
      this.getListData("load");
    },
    onRefresh() {
      this.getListData("init");
    },
    findTradeStatus(){
      this.$Model.TradeStatus((data) => {
        console.log("findTradeStatus")
        if (data['code']==1){
          this.hallInfo = data['info'];
          let info = this.hallInfo;
          if (info['bindAccount']==1) {
            this.$Dialog.Toast(this.$t('common4[7]'));
            this.$router.push("/user/bindAccount");
          }else if (info['shimingauth']==1){
            this.$Dialog.Toast(this.$t('common4[8]'));
            this.$router.push("/shimingauth");
          }else if (info['islock']==2){
            this.$Dialog.Toast(this.$t('common5[5]'));
            this.$router.go(-1);
          }
        }
      });
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }

      let param = {"task_type":1,status:1,page_no: this.pageNo,page_size:200};
      this.$Model.GetHallTaskList(param,(data) => {
        if (data['code']==1){
          // this.selllist = data['info']
          if (type == "load") {
            if (this.pageNo == 1) {
              this.listData = data.info;
            } else {
              this.listData = this.listData.concat(data.info);
            }
          } else {
            this.listData = data.info;
          }
          if (this.pageNo == data.data_total_page) {
            this.isFinished = true;
          } else {
            this.isFinished = false;
          }
        } else {
          this.listData = [];
          this.isFinished = true;
        }
      });
    },
    onClickRight(){
      this.$router.push("/user/userTaskRecord");
    },
    gotoTask(index,item){
      if (this.hallInfo['islock']==2){
        this.$Dialog.Toast(this.$t('common5[5]'));
        return;
      }
      this.currItem = item;
      if (index==1){
        this.show_buy = true;
        this.show_sell = false;
      }else{
        this.show_sell = true;
        this.show_buy = false;
      }

    },
    cancelTask(){
      this.postData.amount = '';
    },
    addNewOrder() {
      let amount = parseFloat(this.postData['amount']);
      let item = this.currItem;
      let hallstatus = this.hallInfo;
      if (amount<=0){
        this.$toast(this.$t('hall.kbip[2]'));
        this.postData.amount = '';
        return;
      }else if (amount>item.remains_number){
        this.$toast(this.$t('hall.kbip[1]'));
        this.postData.amount = '';
        return;
      }else if (item.min_number>0&&(amount<item.min_number||amount>item.max_number)){
        this.postData.amount = '';
        this.$toast(this.$t('hall.kbip[2]'));
        return;
      }else if (amount>item.remains_number){

      }

      this.postData['task_type'] = 2;
      this.postData['task_id'] = item.id;
      this.postData['order_number'] = item['order_number'];
      this.$Model.SubmitTaskOrder(
              this.postData,
              (data) => {
                let msg = '';
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('hall.kbip[2]');
                    break;
                  case -2:
                    msg = this.$t('hall[2]');
                    break;
                  case -3:
                    msg = this.$t('taskOrder2[1]');
                    break;
                  case -4:
                    msg = this.$t('common4[7]');
                    break
                  case -5:
                    msg = this.$t('common4[8]');
                    break
                  default:
                    msg = data.code_dec;
                }
                let duration = 2000;
                if (data.wait){
                  duration = data.wait*1000
                }
                let msgg = {
                  message: msg,
                  duration: duration
                }
                this.$toast(msgg);
                if (data.code==1){
                  this.$router.push("/myTask");
                }
              }
      );
      this.postData.amount = '';
    console.log("xxxxxxxxx")
    },
    gotoBuy(){
      let usertype = this.UserInfo.user_type;
      if (usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        return;
      }else{
        this.$router.push("/buy");
      }
    }
  }
}
</script>
<style scoped>
  .PageBox {
    color: #d7d7d7;
    background-color: #13171A;
  }

  .PageBox >>> .tt3{
    color: #aaaaaa;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
    font-weight: bold;
  }
  .PageBox >>> .van-grid-item__content {
    padding: 10px !important;
    display: flex;
    background-color:#191c23;
  }
  .PageBox .van-cell>>>.van-cell__title{
    font-size: 16px;
    font-weight: bold;
  }

.robotheader{
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 32px;
  height: 32px;
}

.records {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  padding: 5px;
  background-color: #13171A;
}
.Content>>>img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
}

.ScrollBox{
}

  .PageBox >>> .van-grid-item__text{
    font-weight: bolder;
    color: #fff;

  }

  .PageBox>>> .van-dialog {
    background-color: #191C23;
    color: #aaaaaa;
  }

  .PageBox>>> .van-dialog__confirm{
    background-color: #191C23;
    color:#0076FA;
  }

  .van-dialog>>>.van-cell {
    background: #0F1A29;
    color: #fff;
  }

  .van-dialog>>>.van-field__control {
    background-color: #13171A;
    color: #fff;
  }
  .PageBox>>> .van-dialog__cancel{
    background-color: #13171A;
    color: #fff;
  }

.title00 {
  background: #FDEAD9;
  width: 95%;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center
}

.title01 {
  background-color: #191c23;
  width: 95%;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}

.sellitem {
  background-color: #191c23;
  color: #d7d7d7;
  width: 99%;
  text-align: left;
  font-size: 15px;
  margin-top: 5px;
  padding: 10px;
  border-radius: 10px;
}

.PageBox>>>.tool::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -80%;
}
.tool {
  height: 150px;
  border-radius: 10px;
  background-color:#0076FA;
  color: #fff;
  box-shadow: 0 1px 3px #dbdbdbdb;
  overflow: hidden;
  z-index: 99;
  padding: 0 5px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 20px;
}

.ScrollBox>>>.van-cell{
	padding:20px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #191C23;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}
.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}

.hallswipe {
  width: 96%;
  margin-left: 6px;
  padding: 15px;
  height: 60px;
  z-index: 50;
  margin-top: 5px;
  margin-bottom: 5px;
  color:#d7d7d7;
  background-color: #13171A;
}


  .ScrollBox >>> .van-tab :nth-of-type(1) div {
    background-color: #000;
    padding: 5px;
    border: 1px solid;
    border-radius: 20px;
    color: #fff;
  }

  .ScrollBox >>> .van-tab--active :nth-of-type(1) div {
    background-color: #F5C73A;
    padding: 5px;
    border-radius: 20px;
    color: #000;
  }
  .ScrollBox >>> .van-tab--active :nth-of-type(1) div div {
    background-color: #F5C73A;
    padding: 5px;
    border-radius: 20px;
    color: #000;
  }

.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:40px;
	padding:0 20px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
	color: #666666;
}

</style>
